.orders_table_layout {
  padding: 0 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: 31px;
  p {
    word-break: break-all;
  }
  .p-medium {
    color: $BASE_DARK_BLACK_COLOR;
    padding-left: 21px;
    margin-bottom: 15px;
  }

  .order_filter_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ant-calendar-picker-icon {
      margin-top: -11px;
    }
  }

  .table_header {
    display: flex;
    padding: 12px 15px;
    color: $BASE_BLACK_COLOR;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;

    .order,
    .delivery_address,
    .date_time {
      width: 25%;
      display: flex;
      align-items: center;
    }
    .bought,
    .total {
      width: 12.5%;
      display: flex;
      align-items: center;
    }

    .order {
      svg {
        height: 15px;
        margin-right: 22px;
        margin-top: -2px;
      }
    }
  }

  .table_content {
    flex: 1;
    overflow-y: auto;
    .table_item {
      cursor: pointer;
      position: relative;
      display: flex;
      padding: 12px 15px;
      align-items: center;
      border-top: 1px solid $MAIN_BORDER_COLOR;
      .order,
      .delivery_address,
      .date_time {
        width: 25%;
        display: flex;
        flex-direction: column;
      }
      .bought,
      .total {
        width: 12.5%;
        display: flex;
        flex-direction: column;
      }
      .order {
        .code {
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
          color: $BASE_BLACK_COLOR;
          margin-bottom: 8px;
          .order_number {
            margin-right: 4px;
          }
          .reference_id {
            color: #acacac;
          }
        }
        .address {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          color: $BASE_BLACK_COLOR;
          margin-bottom: 8px;
        }
        .phone {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 12px;
          color: $BASE_LIGHT_GRAY_COLOR;
          margin-bottom: 8px;
        }
      }

      .bought {
        .article {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          color: $BASE_BLACK_COLOR;
          margin-bottom: 8px;
        }
        .device {
          width: 58px;
          border-radius: 4px;
          padding: 4px 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
          color: $BASE_BLACK_COLOR;
          background-color: $TABLE_DEVICE_BG_COLOR;
        }
      }

      .delivery_address {
        .address1 {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: $BASE_BLACK_COLOR;
        }
        .address2 {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: $BASE_LIGHT_GRAY_COLOR;
        }
        .product {
          width: fit-content;
          max-width: 120px;
          border-radius: 4px;
          padding: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          color: $BASE_BLACK_COLOR;
          background-color: $TABLE_DEVICE_BG_COLOR;
          text-align: center;
        }
      }

      .date_time {
        .date {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: $BASE_BLACK_COLOR;
        }
        .time {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: $BASE_LIGHT_GRAY_COLOR;
        }
      }

      .total {
        .amount {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: $BASE_BLACK_COLOR;
        }
        .payment_method {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: $BASE_LIGHT_GRAY_COLOR;
        }
      }
    }

    .table_item:nth-child(even) {
      background: $TABLE_BG_COLOR;
    }

    .table_item::before {
      content: '';
      position: absolute;
      width: 4px;
      height: 100%;
      top: 0;
      left: 0;
      background-color: $BASE_GREEN_COLOR;
    }

    .order_RV::before {
      background-color: $Received;
    }
    .order_IP::before {
      background-color: $In_Production;
    }
    .order_AS::before {
      background-color: $Assigned;
    }
    .order_OW::before {
      background-color: $On_the_way;
    }
    .order_AD::before {
      background-color: $At_the_door;
    }
    .order_CL::before {
      background-color: $Claimed;
    }
    .order_RS::before {
      background-color: $Resolving;
    }
    .order_DD::before {
      background-color: $Delivered;
    }
    .order_CD::before {
      background-color: $Cancelled;
    }
  }
}
