.navbar_layout {
  min-width: $NAV_BAR_WIDTH;
  background-color: $BASE_BLACK_COLOR;
  display: flex;
  flex-direction: column;
  padding: 24px 0px;

  .bottom_wrapper {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    .nav_active {
      border: 2px solid $BASE_WHITE_COLOR;
    }
    .plus_wrapper,
    .search_wrapper,
    .avatar_wrapper {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;

      svg {
        width: 17px;
        height: 17px;
        color: $BASE_WHITE_COLOR;
      }

      .avatar,
      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $BASE_LIGHT_GRAY_COLOR;
        cursor: pointer;
      }
    }

    .plus_wrapper {
      background-color: $BASE_YELLOW_COLOR;
    }
    .search_wrapper {
      background-color: $BASE_GRAY_BLACK_COLOR;
      margin: 19px 0px;
    }
  }
}

.ant-tooltip-placement-right {
  left: 70px !important;
}

.account_info_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .name {
    font-weight: bold;
    color: $BASE_BLACK_COLOR;
  }

  .email {
    color: $BASE_GRAY_COLOR;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 13px 0px 13px;
    margin-top: 25px;
    border-top: 1px solid $DIVIDER_BG_COLOR;
    cursor: pointer;

    img {
      width: 14px;
      margin-right: 14px;
    }
  }
}
