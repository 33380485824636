.new_orderlayout {
  width: 100%;
  .main_content {
    display: flex;
    height: 100%;
    overflow: auto;

    .left_panel {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 710px;
      min-height: 700px;
      background-color: $BASE_WHITE_COLOR;
      box-shadow: 15px 2px 25px 0 rgba(0, 0, 0, 0.06);
      z-index: 10;
      padding-left: 87px;

      .header_title {
        position: absolute;
        color: $BASE_LIGHT_GRAY_COLOR;
        top: 77px;
        font-size: 14px;
      }
      .left_content_wrapper {
        .caller_id_label {
          margin-right: 28px;
          .description {
            margin-bottom: 0px;
          }
        }

        .caller_form {
          display: flex;
          flex-wrap: wrap;
          margin-top: 20px;
          .ant-form-item {
            margin-bottom: 0px;
          }
          .ant-form-explain {
            display: none;
          }
          .phone_number_wrapper {
            display: flex;
            background-color: $FORM_ELEMENT_BG_COLOR;
            margin-right: 18px;
            border-radius: 8px;
            .ant-select {
              width: 70px;
              border-right: 1px solid #ebebeb;
              .ant-select-selection {
                padding: 0;
                .ant-select-selection__rendered {
                  margin-right: 0px;
                }
              }
            }
            input {
              border: none;
              background-color: $FORM_ELEMENT_BG_COLOR;
              padding-left: 28px;
              border-radius: 8px;
            }
          }

          .caller_id_input {
            width: 250px;
          }

          .invalid_phone {
            input {
              color: $BASE_DARK_RED_COLOR;
            }
          }

          .flag-dropdown,
          .selected-flag {
            display: none;
          }
        }
      }
      .calls-button {
        margin-top: 16px;
        .ant-btn {
          span{
            margin-right: 4px;
          }
          background-color: $SECONDARY_BG_COLOR;
          color: $BASE_DARK_BLACK_COLOR;
        }
      }
    }
    .right_panel {
      flex: 1;
      .ant-spin-nested-loading {
        height: 100%;
        .ant-spin-container {
          height: 100%;
          position: relative;
          display: flex;
          flex-direction: column;
          min-height: 700px;
          background-color: $BASE_WHITE_COLOR;
          .empty_screen {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            .empty_wrapper {
              text-align: center;
              .title {
                font-style: normal;
                font-weight: bold;
                font-size: 20px;
                line-height: 24px;
                color: $BASE_BLACK_COLOR;
              }
              .subtitle {
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 17px;
                color: $BASE_LIGHT_GRAY_COLOR;
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
  }
}
.modal-calls-type{
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .ant-btn{
    align-self: flex-end;
    //background-color: #979797;
    gap: 4px;
    //color: $BASE_WHITE_COLOR;
  }
  .header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    svg {
      cursor: pointer;
    }
  }
  .select-type{
    width: 320px;
  }
  .title{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: $BASE_BLACK_COLOR;
  }
}
@media (max-width: 1200px) {
  .new_orderlayout {
    .main_content {
      .left_panel {
        width: 400px;

        .left_content_wrapper {
          width: 270px;
          .phone_number_wrapper {
            width: 100%;
            margin-right: 0px;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .new_orderlayout {
    .main_content {
      flex-direction: column;
      .left_panel {
        width: 100vw;
        min-height: 380px;
        box-shadow: none;
        padding-top: 51px;
      }
      .right_panel {
        width: 100vw;
        min-height: auto;
        overflow: unset;
      }
    }
  }
}
