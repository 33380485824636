.checkout_billing_layout {
  width: 100%;
  height: 100%;
  position: relative;

  .ant-btn[disabled] {
    background-color: $DISABLED_BTN_COLOR;
  }

  .checkout_billing_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .order_progress_wrapper {
      padding: 0 27px;
    }

    .back_btn_wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 23px;
      margin-bottom: 35px;

      .back_btn {
        position: absolute;
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px 7px;
        left: 56px;
        border-radius: 50%;
        background-color: $SECONDARY_BG_COLOR;
        cursor: pointer;

        svg {
          color: $MAIN_TEXT_COLOR;
        }
      }
    }

    .ant-form {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .checkout_billing_detail {
      flex: 1;
      overflow: auto;
      padding: 0px 46px;
      margin-top: 20px;
      .prefer_billing_type{
        display: flex;
        justify-content: center;
        margin-top: 32px;
        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 162px;
          height: 100px;
          cursor: pointer;

          img {
            margin-bottom: 5px;
            width: 30px;
            height: 30px;
          }

          p {
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: $MAIN_TEXT_COLOR;
            padding: 0 4px;
          }
        }
        .active {
          border-radius: 11.5px;
          background-color: $MENU_SELECT_BTN_BG_COLOR;
          -webkit-transition: background-color 500ms linear;
          -ms-transition: background-color 500ms linear;
          transition: background-color 500ms linear;
        }
      }
      input {
        width: 100%;
        height: 40px;
        border-radius: 8px;
        background-color: $FORM_ELEMENT_BG_COLOR;
        color: $MAIN_TEXT_COLOR;
        border: none;
        padding: 0 15px;
      }

      .nit_number {
        margin-bottom: 17px;
      }

      .nit_number_wrapper {
        display: flex;
        align-items: center;
        background-color: $FORM_ELEMENT_BG_COLOR;
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px;
          height: 30px;
          background-color: $BASE_YELLOW_COLOR;
          color: $BASE_WHITE_COLOR;
        }
      }

      .bill_name,
      .bill_address {
        .title,
        .description {
          font-weight: normal;
          font-size: 13px;
        }
      }
    }

    .total_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 23px 29px;
      margin-top: 15px;
      background-color: $BASE_BLACK_COLOR;

      .p-large {
        color: $BASE_WHITE_COLOR;
      }

      button {
        display: flex;
        width: 143px;
        height: 40px;
        background-color: $BASE_YELLOW_COLOR;
        border-radius: 10px;
        align-items: center;
        outline: none;
        margin-right: 0px;
        white-space: pre-wrap;
        border: none;
        justify-content: space-between;

        div {
          text-align: left;
          margin-right: 10px;
          p:nth-child(1) {
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 16px;
            color: $BASE_WHITE_COLOR;
          }
        }

        svg {
          color: $BASE_WHITE_COLOR;
        }
      }

      .disabled {
        background-color: $BASE_BTN_DISABLE_COLOR;
      }
    }
  }
}
