.number_picker_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .number_wrapper {
    display: flex;
    align-items: center;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 38px;
      border-radius: 11.5px;
      font-size: 24px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $BASE_WHITE_COLOR;
      cursor: pointer;
      user-select: none;
    }

    .minus {
      background-color: $BASE_BLACK_COLOR;
    }

    .plus {
      background-color: $NUMBER_PICKER_BTN_BG_COLOR;
    }

    p {
      min-width: 55px;
      text-align: center;
      font-size: 35px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $MAIN_TEXT_COLOR;
    }
  }

  .small {
    div {
      width: 24px;
      height: 22px;
      font-size: 12px;
      border-radius: 5px;
    }

    p {
      font-size: 20px;
      margin: 0 10px;
    }
  }
}
