.ant-modal {
  .ant-modal-content {
    background: $BASE_WHITE_COLOR;
    box-shadow: 0px 15px 45px rgba(0, 0, 0, 0.282151);
    border-radius: 0px;

    .ant-modal-header {
      text-align: center;
      border: none;
      padding: 35px 0px 15px 0px;
      border-top-left-radius: 9px;
      border-top-right-radius: 9px;

      img {
        width: 26px;
        margin-right: 20px;
      }
    }

    .ant-modal-body {
      padding: 0;
      display: flex;
      flex-direction: column;
    }

    .wrapper {
      display: flex;
      justify-content: center;

      .basic-input {
        width: 100%;
      }
    }
  }
}

.no-overlay {
  .ant-modal {
    min-width: 977px;
    min-height: 690px;

    .ant-modal-content {
      min-width: 977px;
      min-height: 690px;

      .ant-modal-body {
        padding: 0px;
      }
    }
  }
}
