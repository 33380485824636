.claim_confirm_layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $BASE_YELLOW_COLOR;

  .claim_confirm_wrapper {
    width: 415px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      color: $BASE_WHITE_COLOR;
      margin-top: 27px;
      text-align: center;
    }

    .ant-btn {
      background-color: $BASE_BLACK_COLOR;
      color: $BASE_WHITE_COLOR;
      margin-top: 33px;
    }
  }
}
