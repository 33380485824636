@import 'assets/styles/Global.scss';

@font-face {
  font-family: 'Lato';
  src: url('../../assets/font/Lato/Lato-Regular.ttf');
}

body,
h3,
p,
span,
div,
a,
button,
select,
input {
  font-family: 'Lato';
}

p {
  margin-top: 0px;
  margin-bottom: 0px;
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: $BASE_BLACK_COLOR;
  margin-bottom: 0px;
}

.p-small {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
}

.p-medium {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}

.p-large {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
}

.title {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: $BASE_BLACK_COLOR;
  margin-bottom: 9px;
}

.description {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  color: $FORM_SUBTITLE_COLOR;
  margin-bottom: 16px;
}

#multi_order_tooltip {
  width: 273px;
}

.ant-tooltip-content {
  min-width: 177px;
  text-align: center;

  .ant-tooltip-arrow {
    &::before {
      background-color: $BASE_BLACK_COLOR;
    }
  }
  .ant-tooltip-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $BASE_BLACK_COLOR;
    padding: 4px 13px;
    text-align: center;
  }
}

.ant-time-picker-input,
.ant-input,
#caller_form_caller_id {
  height: 40px;
  background-color: $MAIN_BG_COLOR;
  border-radius: 8px;
  border: none;
  font-size: 13px;
}

.ant-select {
  .ant-select-selection {
    display: flex;
    align-items: center;
    height: 40px;
    background-color: $FORM_ELEMENT_BG_COLOR;
    color: $BASE_BLACK_COLOR;
    border-radius: 8px;
    border: none;
    padding: 8px 14px;
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;

    .ant-select-selection__rendered {
      width: 100%;
    }

    .ant-select-arrow {
      position: unset;
      svg {
        color: $BASE_BLACK_COLOR;
      }
    }
    .ant-select-arrow {
      svg {
        color: $BASE_LIGHT_GRAY_COLOR;
      }
    }
  }
}

.has-error .ant-input,
.has-error .ant-input:hover {
  background-color: $MAIN_BG_COLOR;
}

.ant-btn {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $BASE_YELLOW_COLOR;
  border: none;
  border-radius: 8px;
  color: $BASE_WHITE_COLOR;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;

  svg {
    width: 16px;
    height: 16px;
  }
  transition: 0.6s;
  overflow: hidden;

  &:focus {
    outline: 0;
    background-color: $BASE_YELLOW_COLOR;
    color: $BASE_WHITE_COLOR;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 60px;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0.5;
    filter: blur(30px);
    transform: translateX(-100px) skewX(-15deg);
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 30px;
    height: 100%;
    left: 30px;
    top: 0;
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-100px) skewX(-15deg);
  }
  &:hover {
    background-color: $BASE_YELLOW_COLOR;
    color: $BASE_WHITE_COLOR;
    cursor: pointer;
    &:before {
      transform: translateX(400px) skewX(-15deg);
      opacity: 0.6;
      transition: 0.7s;
    }
    &:after {
      transform: translateX(400px) skewX(-15deg);
      opacity: 1;
      transition: 0.7s;
    }
  }
}
.ant-btn[disabled] {
  background-color: $DISABLED_BTN_COLOR;
}

.ant-progress {
  .ant-progress-inner {
    height: 4px;
    .ant-progress-bg {
      height: 4px !important;
      background-color: $BASE_YELLOW_COLOR;
    }
  }
}
#phone-form-control {
  width: 100%;
  height: 40px;
}

.scrollbar {
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f6f6f6;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #ececec;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.error_msg {
  font-size: 14px;
  color: $BASE_RED_COLOR;
}

.smooth-image {
  transition: opacity 1s;
}
.image-visible {
  display: block;
}
.image-hidden {
  display: none;
}

.fade-in {
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;
}

.step-fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

.ant-spin-nested-loading {
  height: 100%;
  .ant-spin-container {
    height: 100%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.RV {
  background-color: $Received;
}
.IP {
  background-color: $In_Production;
}
.AS {
  background-color: $Assigned;
}
.OW {
  background-color: $On_the_way;
}
.AD {
  background-color: $At_the_door;
}
.CL {
  background-color: $Claimed;
}
.RS {
  background-color: $Resolving;
}
.DD {
  background-color: $Delivered;
}
.CD {
  background-color: $Cancelled;
}

.order_text_RV {
  color: $Received;
}
.order_text_IP {
  color: $In_Production;
}
.order_text_AS {
  color: $Assigned;
}
.order_text_OW {
  color: $On_the_way;
}
.order_text_AD {
  color: $At_the_door;
}
.order_text_CL {
  color: $Claimed;
}
.order_text_RS {
  color: $Resolving;
}
.order_text_DD {
  color: $Delivered;
}
.order_text_CD {
  color: $Cancelled;
}
