.food_menu_layout {
  padding: 0 30px;

  .title {
    font-size: 26px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $MAIN_TEXT_COLOR;
    margin-bottom: 23px;
  }

  .food_day_part_wrapper {
    display: flex;
    width: 100%;
    padding: 0 10px;
    margin-top: 20px;
    div {
      padding: 6px 27px;
      font-size: 14px;
      font-weight: bold;
      color: $MAIN_TEXT_COLOR;
      cursor: pointer;
    }
    .active {
      border-radius: 11.5px;
      background: $CATEGORY_FILTER_BTN_BG_COLOR;
    }
  }

  .food_menu_wrapper {
    display: flex;
    flex-wrap: wrap;

    .menu_item {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 1 1 73px;
      max-width: 73px;
      cursor: pointer;
      -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

      &::after {
        content: '';
        border-radius: 5px;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 15px 20px rgba(0, 0, 0, 0.1);
        opacity: 0;
        -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
        transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      }

      &:hover {
        -webkit-transform: scale(1.05, 1.05);
        transform: scale(1.05, 1.05);
        &::after {
          opacity: 1;
        }
      }

      &:not(:last-child) {
        margin-right: 30px;
      }

      div {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 73px;
        height: 73px;

        img {
          height: 35px;
        }
      }

      p {
        width: 73px;
        text-align: center;
        word-break: break-word;
      }
    }

    .active {
      p {
        color: $BASE_YELLOW_COLOR;
        font-weight: bold;
      }
    }
    .invalid {
      opacity: 0.3;
    }
  }

  .food_submenu_wrapper {
    display: flex;
    align-items: center;
    margin-top: 45px;
    overflow-y: auto;

    .favorite_item {
      cursor: pointer;
      margin-right: 15px;
      text-align: center;
    }
    .sub_menu_item {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 4px 18px;

      cursor: pointer;

      p {
        text-align: center;
      }
      &:not(:last-child) {
        margin-right: 15px;
      }
    }

    .sub_active {
      background: #e5eaee;
      border-radius: 11.5px;
      padding: 4px 18px;
    }
  }
}

@media (max-width: 768px) {
  .food_menu_layout {
    padding: 108px 0px 0px 28px;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.146252);

    .food_menu_wrapper {
      flex-wrap: nowrap;
      overflow-x: auto;

      /* width */
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f6f6f6;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #ececec;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
}
