.cart_layout {
  width: 100%;
  height: 100%;
  position: relative;

  .empty_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      width: 234px;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: center;
      margin-top: 27px;
    }
  }

  .cart_content_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .order_progress_wrapper {
      padding: 0 27px;
    }

    .cart_title {
      font-size: 20px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $MAIN_TEXT_COLOR;
      margin-top: 40px;
    }

    .product_list {
      padding: 0 24px;
      margin-top: 34px;
      flex: 1;
      overflow: auto;

      .product {
        display: flex;
        margin-bottom: 28px;

        .product_brand {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 104px;
          height: 104px;
          border-radius: 20px;
          box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.03);
          background-color: $BASE_WHITE_COLOR;
          padding: 22px 5px;
          margin-right: 20px;
          img {
            height: 100%;
          }
        }

        .product_detail {
          flex: 1;
          .name {
            font-size: 14px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.43;
            letter-spacing: normal;
            color: $MAIN_TEXT_COLOR;
          }

          .product_description,
          .quantity {
            font-size: 11px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.82;
            letter-spacing: normal;
            color: $SECONDARY_TEXT_COLOR;
          }

          .change_quantity {
            display: flex;
            align-items: center;
            margin-top: 10px;

            .divider {
              width: 2px;
              height: 40px;
              background-color: $DIVIDER_BG_COLOR;
            }
            .clear_all {
              margin: 0 7px;
            }
            .close {
              margin-left: 7px;
            }
            .clear_all,
            .close {
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 29px;
              min-height: 27px;
              border-radius: 7px;
              background-color: $BASE_LIGHT_GRAY_COLOR;
              cursor: pointer;

              svg {
                width: 14px;
                height: 14px;
              }
            }
          }
        }
      }
    }
    .cart_price_limit_wrapper{
      display: flex;
      flex-direction: row;
      gap: 8px;
      padding: 23px 29px;
      margin-top: 15px;
      .ant-switch-checked {
        background-color: $BASE_GREEN_COLOR;
      }
    }
    .total_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 23px 29px;
      margin-top: 15px;
      background-color: $BASE_BLACK_COLOR;

      .p-large {
        color: $BASE_WHITE_COLOR;
      }
      .p-medium {
        font-size: 12px;
        color: $BASE_WHITE_COLOR;
      }
      button {
        display: flex;
        width: 143px;
        height: 40px;
        background-color: $BASE_YELLOW_COLOR;
        border-radius: 10px;
        align-items: center;
        outline: none;
        margin-right: 0px;
        white-space: pre-wrap;
        border: none;
        justify-content: space-between;

        div {
          text-align: left;
          margin-right: 10px;
          p:nth-child(1) {
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 16px;
            color: $BASE_WHITE_COLOR;
          }
        }

        svg {
          color: $BASE_WHITE_COLOR;
        }
      }
    }
  }
}
