.Toastify__toast-container {
  left: 0;
  top: 0;
  margin-left: 0px;
  padding: 0;

  .Toastify__toast {
    width: 100vw;
    min-height: 52px;
    left: 0;
    padding: 0;
    box-shadow: 0 12px 30px rgba(79, 75, 108, 0.159282);
    .Toastify__toast-body {
      display: flex;
      align-items: center;
      margin: 0;
      color: $BASE_WHITE_COLOR;

      .toast_container_wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding-left: 25px;
        min-height: 52px;
        padding-right: 40px;
        p {
          color: $BASE_WHITE_COLOR;
          margin-bottom: 0px;
        }
      }

      .success {
        background: $BASE_GREEN_COLOR;
      }
      .error {
        background: $BASE_DARK_RED_COLOR;
      }

      .info {
        background: $BASE_YELLOW_COLOR;
      }
    }

    .Toastify__close-button {
      position: absolute;
      right: 0;
      color: $BASE_WHITE_COLOR;
      opacity: 1;
      top: 15px;
      right: 25px;
    }
  }
}
