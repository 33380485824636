.food_list_layout {
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  .inactive {
    opacity: 0.3;
  }
  .food_item {
    flex: 1 1 120px;
    max-width: 120px;
    margin-bottom: 28px;
    align-items: center;
    padding-right: 20px;
    cursor: pointer;

    .food_item_wrapper {
      position: relative;
      display: inline-block;
      -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      padding: 5px;
      &::after {
        content: '';
        border-radius: 5px;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 15px 20px rgba(0, 0, 0, 0.1);
        opacity: 0;
        -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
        transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      }

      &:hover {
        -webkit-transform: scale(1.05, 1.05);
        transform: scale(1.05, 1.05);
        &::after {
          opacity: 1;
        }
      }
    }
    .food_img {
      display: flex;
      width: 104px;
      height: 104px;
      min-width: 104px;
      min-height: 104px;
      border-radius: 20px;
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.03);
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
      }
    }

    .food_content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .name {
        font-size: 11px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.82;
        letter-spacing: normal;
        color: $SECONDARY_TEXT_COLOR;
        margin-top: 6px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 16px; /* fallback */
        max-height: 16px; /* fallback */
        -webkit-line-clamp: 1; /* number of lines to show */
        -webkit-box-orient: vertical;
        text-align: center;
      }

      .amount {
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.25;
        letter-spacing: normal;
        color: $MAIN_TEXT_COLOR;
        margin-top: 6px;
        text-align: center;
      }
    }
  }
}

@media (max-width: 768px) {
  .food_list_layout {
    padding-left: 28px;
    margin-right: 20px;
  }
}
