.main_layout {
  display: flex;

  .content {
    display: flex;
    box-sizing: border-box;
    height: 100vh;
    flex: 1;
    background-color: $BASE_WHITE_COLOR;
    overflow-y: auto;
  }

  /* width */
  .content::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  .content::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  .content::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  .content::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
