.category_layout {
  width: 100%;
  .main_content {
    display: flex;
    height: 100%;
    overflow: auto;

    .left_panel {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 700px;
      overflow: auto;
      z-index: 10;

      .content_header {
        display: flex;
        align-items: center;
        padding: 35px 26px 0px 26px;
        .back_btn {
          width: 38px;
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 7px 7px;
          border-radius: 50%;
          background-color: $SECONDARY_BG_COLOR;
          cursor: pointer;

          svg {
            color: $MAIN_TEXT_COLOR;
          }
        }

        p {
          font-size: 12px;
          margin-left: 19px;
        }
        p:first-child {
          font-size: 14px;
          color: $MAIN_TEXT_COLOR;
        }
        p:last-child {
          span {
            font-weight: bold;
            color: black;
          }
        }
      }
    }

    .right_panel {
      width: 423px;
      position: relative;
      overflow: auto;
      min-height: 700px;
      background-color: $BASE_WHITE_COLOR;
      z-index: 999;
      box-shadow: -5px 0 25px 0 rgba(0, 0, 0, 0.09);
    }
  }
}

@media (max-width: 1200px) {
  .category_layout {
    .main_content {
      .left_panel {
        padding: 35px 0px 0px 20px;
      }
    }
  }
}

@media (max-width: 900px) {
  .category_layout {
    padding: 0;
    .main_content {
      flex-direction: column;
      .left_panel {
        overflow: unset;
        min-height: auto;

        .content_header {
          margin-bottom: 20px;
        }

        .food_menu_layout {
          padding: 20px 0px 0px 20px;
        }
      }

      .right_panel {
        width: 100vw;
        overflow: unset;
        min-height: auto;

        .cart_layout {
          .cart_content_wrapper {
            .product_list {
              padding: 0 20px;
              .product {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .product_detail {
                  text-align: center;
                }
              }
            }

            .order_btn_wrapper {
              button {
                width: 100%;
                max-width: 400px;
              }
            }
          }
        }
      }
    }
  }
}
