.busy_time_modal {
  .ant-modal-content {
    background: $BASE_WHITE_COLOR;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.154993);
    padding: 60px 46px 16px 52px;

    .modal_header {
      display: flex;
      flex-direction: column;
      padding-bottom: 16px;
      border-bottom: 1px solid #f1f1f1;
      .description {
        font-size: 12px;
        line-height: 22px;
        margin-top: 8px;
        margin-bottom: 0px;
      }
    }

    .modal_content {
      .delivery_time_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .dropdown_wrapper {
          width: 100%;
          display: flex;
          align-items: center;
          .date_list,
          .time_list {
            flex: 1;
            margin-top: 27px;
          }
          .date_list {
            margin-right: 22px;
          }
        }
      }
    }

    .modal_footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: 16px;

      .btn_wrapper {
        display: flex;
        align-items: center;

        p {
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          color: $BASE_BLACK_COLOR;
          cursor: pointer;
          margin-right: 37px;
        }

        .remove_btn {
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 4px;
          border: none;
          font-size: 12px;
          background-color: $BASE_YELLOW_COLOR;
          color: $BASE_WHITE_COLOR;
          svg {
            width: 16px;
            height: 16px;
            margin-left: 16px;
          }
        }
      }
    }
  }
}
