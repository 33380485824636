.available_list_wrapper {
  height: calc(100% - 265px);
  display: flex;
  flex-direction: column;
  padding: 19px 0px;
  flex: 1;

  .list_header_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 43px;

    .ant-btn {
      background-color: $SECONDARY_BG_COLOR;
      color: $BASE_DARK_BLACK_COLOR;
    }
  }
  .list_wrapper {
    flex: 1;
    padding: 0 43px;
    overflow-y: auto;
    margin-top: 20px;

    .empty_list {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .ant-btn {
        background-color: $SECONDARY_BG_COLOR;
        color: $BASE_DARK_BLACK_COLOR;
        margin-top: 32px;
      }
    }

    .item {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 13px 27px;

      .address-edit-btn-wrapper {
        display: flex;
        align-items: center;
      }

      svg {
        width: 16px;
        height: 16px;
        margin-right: 14px;
        color: $BASE_DARK_BLACK_COLOR;
      }
      .address_name {
        width: calc(100% - 80px);
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: $BASE_BLACK_COLOR;
      }
      .add_order {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 38px;
        height: 35px;
        border-radius: 6px;
        background-color: $SECONDARY_BG_COLOR;
        margin-left: 27px;
        svg {
          width: 15px;
          height: 15px;
          margin-right: 0px;
        }
      }
      .disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }
      .invalid_address {
        color: $BASE_DARK_RED_COLOR;
      }
      .delete-icon {
        margin-left: 20px;
        display: none;
      }

      &:hover {
        .delete-icon {
          display: block;
        }
      }
    }

    .active-item {
      border: 2px solid $BASE_YELLOW_COLOR;
      border-radius: 15px;
      box-sizing: border-box;
      .address_name {
        color: $BASE_YELLOW_COLOR;
      }
    }
  }
  .list_footer {
    margin-top: auto;

    .store_service_time {
      margin-top: 7px;
      span {
        font-weight: bold;
        color: black;
      }
    }

    .restaurant_note_wrapper {
      display: flex;
      align-items: center;
      .warning {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: $SECONDARY_BG_COLOR;
        cursor: pointer;
        margin-left: 20px;

        svg {
          width: 15px;
        }
      }
      .warning {
        .active {
          color: $BASE_YELLOW_COLOR;
        }
      }
    }

    .delivery_time_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .dropdown_wrapper {
        display: flex;
        align-items: center;
        .date_list,
        .time_list {
          width: 174px;
          margin-top: 27px;
        }
        .date_list {
          margin-right: 22px;
        }
      }

      .ant-btn {
        margin-top: 27px;
      }
    }
  }

  .hide {
    height: 0;
    overflow: hidden;
  }
  .show {
    height: 0;
    padding: 0px 43px;
    border-top: 1px solid $MAIN_BORDER_COLOR;
    -moz-animation: scheduleSlide 0.3s ease 0.2s forwards;
    -webkit-animation: scheduleSlide 0.3s ease 0.2s forwards;
    -o-animation: scheduleSlide 0.3s ease 0.2s forwards;
    -ms-animation: scheduleSlide 0.3s ease 0.2s forwards;
    animation: scheduleSlide 0.3s ease 0.2s forwards;
  }
}

@-moz-keyframes scheduleSlide /* Firefox */ {
  from {
    height: 0;
    padding: 0px 43px;
  }
  to {
    height: 145px;
    padding: 30px 43px;
  }
}

@-webkit-keyframes scheduleSlide /* Safari and Chrome */ {
  from {
    height: 0;
    padding: 0px 43px;
  }
  to {
    height: 145px;
    padding: 30px 43px;
  }
}

@-ms-keyframes scheduleSlide /* IE10 */ {
  from {
    height: 0;
    padding: 0px 43px;
  }
  to {
    height: 145px;
    padding: 30px 43px;
  }
}

@keyframes scheduleSlide {
  from {
    height: 0;
    padding: 0px 43px;
  }
  to {
    height: 145px;
    padding: 30px 43px;
  }
}
