.delivery_update_modal {
  .ant-modal-content {
    background: $BASE_WHITE_COLOR;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.154993);
    padding: 32px;

    .ant-select {
      width: 316px;
      .ant-select-selection {
        display: flex;
        align-items: center;
        height: 32px;
        box-sizing: border-box;
        background-color: $MAIN_BG_COLOR;
        color: $INPUT_MAIN_COLOR;
        border-radius: 4px;
        border: none;
        padding: 8px 14px;
        outline: none;
        justify-content: space-between;
        cursor: pointer;
        font-size: 12px;
        font-weight: normal;
        line-height: 14px;

        svg {
          width: 18px;
        }
      }
    }

    input,
    textarea {
      border-radius: 4px;
      background-color: $MAIN_BG_COLOR;
      border: none;
      font-weight: normal;
      font-size: 12px;
      color: $INPUT_MAIN_COLOR;
    }

    .modal_content {
      display: flex;
      align-items: center;

      .close_btn {
        position: absolute;
        top: 15px;
        right: 19px;
        cursor: pointer;
        svg {
          color: $BASE_BLACK_COLOR;
        }
      }

      .label {
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: $BASE_DARK_BLACK_COLOR;
      }

      .map_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 40%;
        height: 600px;
        padding-right: 35px;
      }

      .address_setting_wrapper {
        width: 60%;
        h3 {
          margin-top: 42px;
        }

        .address_direction {
          position: relative;
          .address_detail {
            margin-top: 21px;
            margin-bottom: 13px;
          }
        }

        .custom_address {
          margin-top: 43px;

          .address {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 23px;

            .ant-dropdown-trigger {
              width: 316px;
            }
            .address_reference {
              width: 316px;
              height: 32px;
              padding-left: 24px;
            }
          }

          .restaurant {
            margin-top: 50px;
            .ant-dropdown-trigger {
              width: 242px;
              margin-right: 9px;
            }

            > div {
              display: flex;
              align-items: center;
            }
          }
        }

        .footer {
          border-top: 1px solid $DIVIDER_BG_COLOR;
          padding-top: 16px;
          .confirm-wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            .ant-switch-checked {
              background-color: $BASE_YELLOW_COLOR;
            }
            span {
              font-size: 12px;
              color: $BASE_DARK_BLACK_COLOR;
              margin-left: 10px;
            }
          }
          .footer-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .aditional_detail {
              .geo_position {
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                color: $USER_DELIVERY_UPDATE_FOODER_COLOR;
              }
            }

            .btn_wrapper {
              display: flex;
              align-items: center;

              .continue_btn {
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 4px;
                border: none;
                font-size: 12px;
                color: $BASE_WHITE_COLOR;
                svg {
                  width: 16px;
                  height: 16px;
                  margin-left: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}
