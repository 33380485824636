.location_input_wrapper {
  display: flex;
  align-items: center;
  background-color: $MAIN_BG_COLOR;
  padding: 6px 10px;
  border-radius: 8px;

  input {
    height: 40px;
    border-radius: 4px;
    background-color: $FORM_ELEMENT_BG_COLOR;
    color: $BASE_BLACK_COLOR;
    font-size: 12px;
    border: none;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 33px;
    padding: 0;
  }
}

.autocomplete-dropdown-container {
  position: absolute;
  width: 100%;
  z-index: 10;
  top: 160px;
  left: 0;
  background-color: $FORM_ELEMENT_BG_COLOR;
  div {
    padding: 10px 10px;
  }
}
