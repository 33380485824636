.existed_orders_layout {
  width: 100%;
  .main_content {
    display: flex;
    height: 100%;
    overflow: auto;

    .right_panel {
      flex: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      min-height: 700px;
      background-color: $BASE_WHITE_COLOR;

      .existing_order_header {
        display: flex;
        align-items: center;
        color: $BASE_DARK_BLACK_COLOR;
        padding: 0 27px;
        margin-top: 39px;

        .back_btn {
          min-width: 38px;
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 23px;
          background-color: $SECONDARY_BG_COLOR;
          border-radius: 50%;
          cursor: pointer;

          svg {
            width: 22px;
            height: 22px;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .existed_orders_layout {
    .main_content {
      .left_panel {
        width: 400px;

        .left_content_wrapper {
          width: 270px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .existed_orders_layout {
    .main_content {
      flex-direction: column;
      .left_panel {
        width: 100vw;
        min-height: 380px;
        box-shadow: none;
        padding-top: 51px;
      }
      .right_panel {
        width: 100vw;
        min-height: auto;
        overflow: unset;
      }
    }
  }
}
