.order_detail_modal {
  .detail_content {
    padding: 22px 0px 26px 22px;
    .detail_header {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      p {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 17px;
        color: $BASE_BLACK_COLOR;
        margin-right: 15px;
      }
      button {
        width: auto;
        height: 20px;
        border: none;
        border-radius: 8px;
        color: $BASE_WHITE_COLOR;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 12px;
      }
      .track_order {
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin-left: 18px;
      }
    }

    .order_details,
    .order_items {
      .group_name {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 14px;
        letter-spacing: -0.288889px;
        color: $ORDER_MODAL_GROUP_COLOR;
        margin-bottom: 19px;

        .divider {
          flex: 1;
          height: 1px;
          background-color: $ORDER_MODAL_GROUP_DEVIDER_COLOR;
          margin-left: 28px;
        }
      }

      .title,
      .description {
        font-style: normal;
        font-size: 13px;
        line-height: 14px;
        letter-spacing: -0.288889px;
      }

      .title {
        font-weight: bold;
        color: $BASE_DARK_BLACK_COLOR;
        margin-bottom: 10px;
      }
      .description {
        font-weight: normal;
        color: $BASE_BLACK_COLOR;
      }
      .sub_description {
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 24px;
        letter-spacing: -0.288889px;
        color: $ORDER_MODAL_SUB_DESCRIPTION_COLOR;
      }
    }

    .order_details {
      .order_number_elapsed {
        display: flex;
        padding-right: 25px;
        justify-content: space-between;
        margin-bottom: 27px;
      }

      .delivery_address,
      .delivery_time,
      .order_name {
        margin-bottom: 20px;
      }
    }

    .order_items {
      .item_list {
        position: relative;
        padding-left: 14px;
        margin-left: 27px;

        .item {
          .name {
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 20px;
            color: $BASE_BLACK_COLOR;
          }
          .additional {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            color: $BASE_DARK_BLACK_COLOR;
          }
          .amount {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            color: #777f92;
          }
        }

        .item:not(:last-child) {
          margin-bottom: 14px;
        }
      }

      .item_list::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;
        background-color: $ORDER_MODAL_ITEMS_LEFT_BAR_COLOR;
      }

      .order_total {
        text-align: right;
        padding-right: 40px;
        margin-top: 20px;

        .title {
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 24px;
          color: $BASE_BLACK_COLOR;
        }
        .amount {
          font-style: normal;
          font-weight: bold;
          font-size: 17px;
          line-height: 24px;
          color: $BASE_BLACK_COLOR;
        }
      }
    }
  }

  .detail_footer {
    padding: 26px 0px 23px 29px;
    background-color: $ORDER_MODAL_CARD_BG_COLOR;

    .pay_way_title {
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 14px;
      letter-spacing: -0.288889px;
      color: $BASE_DARK_BLACK_COLOR;
    }

    .card_info {
      display: flex;
      align-items: center;
      margin-top: 13px;

      img {
        width: 27px;
        height: 19px;
        margin-right: 17px;
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: -0.288889px;
        color: $ORDER_MODAL_PAY_WAY_COLOR;
      }
    }
  }
}
