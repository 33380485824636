.checkout_CC_layout {
  width: 100%;
  height: 100%;
  position: relative;

  .checkout_CC_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .ant-btn[disabled] {
      background-color: $DISABLED_BTN_COLOR;
    }
    .order_progress_wrapper {
      padding: 0 27px;
    }

    .back_btn_wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 23px;
      margin-bottom: 35px;

      .back_btn {
        position: absolute;
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px 7px;
        left: 56px;
        border-radius: 50%;
        background-color: $SECONDARY_BG_COLOR;
        cursor: pointer;

        svg {
          color: $MAIN_TEXT_COLOR;
        }
      }
    }

    .ant-form {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .summary_tooltip_wrapper {
      margin-bottom: 40px;
      .summary_tooltip {
        display: flex;
        align-items: center;
        padding: 4px 12px;
        border-radius: 24px;
        border: 1px solid #e8e8e8;
        cursor: pointer;

        svg {
          margin-left: 12px;
        }
      }
    }

    .gift_card_wrapper {
      padding: 0px 46px;
      margin-top: 20px;
      input {
        width: 100%;
        height: 40px;
        border-radius: 8px;
        background-color: $FORM_ELEMENT_BG_COLOR;
        color: $MAIN_TEXT_COLOR;
        border: none;
        padding: 0 15px;
      }

      .gift_card_number {
        margin-bottom: 17px;
      }

      .gift_card_input {
        display: flex;
        align-items: center;
        background-color: $FORM_ELEMENT_BG_COLOR;
        .gift-card-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px;
          height: 30px;
          background-color: $BASE_YELLOW_COLOR;
          color: $BASE_WHITE_COLOR;
          cursor: pointer;
          border-radius: 10px;
        }
      }

      .gift-card-info {
        display: flex;
        align-items: center;
        i {
          cursor: pointer;
          font-weight: bold;
          color: black;
          margin-right: 12px;
        }
        p {
          color: #5ea328;
          font-weight: bold;
          font-size: 10px;
        }
      }
    }

    .gift-card-cover {
      padding: 25px 63px;
      color: #242c40;
      font-weight: bold;
      font-size: 10px;
    }

    .payment_method_wrapper {
      margin-top: 20px;
      .payment_method {
        max-width: 210px;
        display: flex;
        align-items: center;
        margin-left: 56px;
        padding: 8px 14px;
        cursor: pointer;

        img {
          margin-right: 11px;
        }
      }

      .active {
        background: $MENU_SELECT_BTN_BG_COLOR;
        border-radius: 11.5px;
        -webkit-transition: background-color 500ms linear;
        -ms-transition: background-color 500ms linear;
        transition: background-color 500ms linear;
      }
    }

    .checkout_CC_detail {
      margin-top: 30px;
      flex: 1;
      overflow: auto;
      padding: 0px 46px;
      input {
        width: 100%;
        height: 40px;
        border-radius: 8px;
        background-color: $FORM_ELEMENT_BG_COLOR;
        color: $MAIN_TEXT_COLOR;
        border: none;
        padding: 0 15px;
      }
      .ant-select .ant-select-selection .ant-select-arrow svg {
        color: $BASE_DARK_GRAY_COLOR;
      }
      .payment_list_wrapper {
        margin-bottom: 15px;
        .payment_list{
          padding-top: 2px;
        }
        .ant-select {
          .ant-select-selection__rendered {
            .ant-select-selection-selected-value {
              width: 100%;
              i {
                display: none;
              }
            }
          }
        }
      }
      .checkout_title {
        margin-bottom: 15px;
      }
      .expire_select_wrapper {
        display: flex;

        .ant-form-item {
          flex: 1;

          &:first-child {
            padding-right: 9px;
          }
          &:last-child {
            padding-left: 9px;
          }
        }
      }

      .cash_note {
        font-weight: normal;
        font-size: 13px;
        line-height: 21px;
        color: $MAIN_TEXT_COLOR;
        margin-top: 10px;
        margin-bottom: 39px;
      }
      .visa_link_wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .visa_link_show_auth{
          display: flex;
          align-items: center;
          >span{
            font-size: 11px;
            margin-left: 12px;
          }
          .visa_link_show_auth_button{
            height: 32px;
            background-color: $SECONDARY_BG_COLOR;
            span{
              color: $BASE_DARK_BLACK_COLOR;
            }
            img{
              margin-left: 8px;
            }
            &.active{
              background-color: #EBEBEB;
              span{
                color: #9F9F9F;
              }
            }
          }
          .visa_link_show_auth_success{
            display: flex;
            margin-left: 12px;
            span{
              font-size: 13px;
            }
            align-items: center;
            flex-direction: row;
          }
        }
        .visa_link_button{
          height: 32px;
        }
        .visa_link_generate{
          .success {
            background-color: $BASE_GREEN_COLOR;
          }
          .error {
            background-color: $BASE_RED_COLOR;
          }
          display: flex;
          gap: 8px;
        }

      }
    }
    .option-wrapper{
      padding: 0 46px 20px;
      overflow: scroll;
      .select-address {
        width: unset;
        min-width: 100%;
        max-width: unset;
      }
      svg {
        color: black;
      }
      .store_service_time {
        margin-top: 20px;
        font-size: 12px;
        span {
          font-weight: bold;
          color: black;
        }
      }
      .delivery_time_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .dropdown_wrapper {
          display: flex;
          align-items: center;

          .date_list,
          .time_list {
            width: 155px;
            margin-top: 27px;
          }

          .date_list {
            margin-right: 22px;
          }
        }
      }
    }

    .total_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 23px 29px;
      margin-top: auto;
      background-color: $BASE_BLACK_COLOR;

      .p-large,
      .p-medium {
        color: $BASE_WHITE_COLOR;
      }

      button {
        display: flex;
        width: 143px;
        height: 40px;
        background-color: $BASE_YELLOW_COLOR;
        border-radius: 10px;
        align-items: center;
        outline: none;
        margin-right: 0px;
        white-space: pre-wrap;
        border: none;
        justify-content: space-between;

        div {
          text-align: left;
          margin-right: 10px;
          p:nth-child(1) {
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 16px;
            color: $BASE_WHITE_COLOR;
          }
        }

        svg {
          color: $BASE_WHITE_COLOR;
        }
      }

      .disabled {
        background-color: $BASE_BTN_DISABLE_COLOR;
      }
    }
  }
}

.payment_option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
