.order_confirm_layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $BASE_YELLOW_COLOR;

  .order_confirm_wrapper {
    width: 415px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      color: $BASE_WHITE_COLOR;
      margin-top: 27px;
      text-align: center;
    }

    p {
      margin-top: 14px;
      text-align: center;
    }

    .store_service_time {
      span {
        font-weight: bold;
        color: black;
      }
    }

    .btn_finish {
      width: 204px;
      background-color: $BASE_BLACK_COLOR;
      color: $BASE_WHITE_COLOR;
      margin-top: 33px;
    }

    .btn_multi_order {
      width: 204px;
      margin-top: 30px;
      background: $BASE_YELLOW_COLOR;
      border: 2px solid $BASE_WHITE_COLOR;
      box-sizing: border-box;
      border-radius: 8px;
    }
  }
}
