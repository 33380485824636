.last_order_layout {
  width: 100%;
  margin-top: 20px;
  padding-right: 50px;

  .order_date {
    font-size: 10px;
    color: #a6a6a6;
  }

  .order_detail_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 16px 23px;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    margin-top: 11px;
    margin-bottom: 15px;
    cursor: pointer;
    &:hover {
      background-color: #f2f2f2;
    }
    .user_info {
      flex: 1;
      margin-right: 40px;

      .code {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: $BASE_BLACK_COLOR;
        margin-bottom: 8px;
      }
      .name {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: $BASE_BLACK_COLOR;
        margin-bottom: 8px;
      }
      .phone {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        color: $BASE_LIGHT_GRAY_COLOR;
        margin-bottom: 8px;
      }
    }
    .order_info {
      flex: 3;

      .address1 {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: $BASE_BLACK_COLOR;
      }
      .address2 {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: $BASE_LIGHT_GRAY_COLOR;
      }
      .product {
        width: fit-content;
        max-width: 120px;
        border-radius: 4px;
        padding: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: $BASE_BLACK_COLOR;
        background-color: $TABLE_DEVICE_BG_COLOR;
        text-align: center;
      }
    }
  }

  .order_summary {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 50px;
    .bought {
      margin-right: 23px;
      .article {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: $BASE_BLACK_COLOR;
        margin-bottom: 8px;
      }
      .device {
        width: 58px;
        border-radius: 4px;
        padding: 4px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        color: $BASE_BLACK_COLOR;
        background-color: $TABLE_DEVICE_BG_COLOR;
      }
    }
    .total {
      .amount {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: $BASE_BLACK_COLOR;
      }
      .payment_method {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: $BASE_LIGHT_GRAY_COLOR;
      }
    }
  }
}
