.new_user_option {
  border-bottom: 1px solid $ORDER_MODAL_GROUP_DEVIDER_COLOR;
}
.client_select_wrapper {
  border-bottom: 1px solid $MAIN_BORDER_COLOR;
  padding-bottom: 30px;

  .order_progress_wrapper {
    padding: 0 27px;
  }

  .client_list_wrapper {
    margin-top: 55px;
    h3 {
      text-align: center;
    }
    .client_dropdown {
      padding: 0px 50px;
      margin-top: 59px;
      .p-medium {
        color: $BASE_DARK_BLACK_COLOR;
        margin-bottom: 18px;
      }
      .client_list_wrapper {
        display: flex;
        align-items: center;

        .client_list {
          width: 100%;
          .inactive {
            color: $BASE_RED_COLOR;
          }
        }

        .edit_icon {
          height: 16px;
          color: #687992;
          margin-left: 22px;
          cursor: pointer;
        }
      }
      .tags_list_wrapper {
        display: flex;
        width: 100%;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: 12px;
        gap: 12px;
        .text_info_wrapper {
          display: flex;
          align-items: center;
          border-radius: 8px;
          width: 280px;
          background-color: $FORM_ELEMENT_BG_COLOR;
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 12px;
            margin-right: 12px;
            height: 24px;
            background-color: $BASE_YELLOW_COLOR;
            color: $BASE_WHITE_COLOR;
          }
        }
        .tag_item {
          background: #ffffff;
          border: 2px solid #edf0f2;
          border-radius: 8px;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 2px 0 2px 12px;
          font-size: 11px;
          color: #687992;
          margin-bottom: 10px;
          .edit_icon {
            background: #ffffff;
            height: 16px;
            border-radius: 100px;
            color: #687992;
            margin-left: 12px;
            cursor: pointer;
          }
        }
        .tag_item.add_padding {
          padding: 2px 12px 2px 12px;
        }
      }
    }
    .new_customer_wrapper {
      padding: 0 27px;
      .title {
        font-size: 14px;
        color: $BASE_DARK_BLACK_COLOR;
        margin-top: 59px;
        margin-bottom: 18px;
      }
      .reference_name_wrapper {
        display: flex;
        align-items: center;
        .first_name {
          flex: 1;
          padding-right: 10px;
        }
        .last_name {
          flex: 1;
          padding-left: 10px;
        }
      }
      .btn_wrapper {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}


.back_btn_wrapper {
  position: relative;
}
.back_btn {
  position: absolute;
  left: 10%;
  top:0;
  border-radius: 50%;
  padding: 7px 7px;
  height: 38px;
  border-radius: 50%;
  background-color: #edf0f2;
  padding: 7px 7px;
  cursor: pointer;
  svg {
    color: #242c40;
  }
}
