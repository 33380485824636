.login_layout {
  display: flex;
  height: 100vh;
  background-image: url('/OTSWallpaper.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .ant-spin-nested-loading {
    width: 100%;
    .ant-spin-container {
      height: 100%;

      .left_wrapper {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: $Auth_LEFT_WIDTH;
        height: 100%;
        background-color: $BASE_BLACK_COLOR;
        padding: 77px 25px 25px 25px;

        .login_wrapper {
          padding: 0 16px;

          .title {
            color: $BASE_WHITE_COLOR;
            font-size: 22px;
            font-weight: normal;
            line-height: 35px;
            letter-spacing: -0.220106px;
          }

          .description {
            font-size: 13px;
            line-height: 25px;
            font-weight: normal;
            color: $BASE_GRAY_COLOR;
            margin-bottom: 26px;
          }

          .ant-form-item {
            margin-bottom: 16px;

            input {
              height: 32px;
              background-color: $INPUT_SECONDARY_BG;
              color: $BASE_DARK_GRAY_COLOR;
              border: none;
              font-weight: normal;
              font-size: 12px;
              border-radius: 4px;
              padding-left: 40px;
            }

            .ant-input-prefix {
              svg {
                width: 15px;
                height: 15px;
                color: $BASE_WARM_GRAY_COLOR;
              }
            }
          }

          .login-form-btn {
            display: flex;
            justify-content: flex-end;

            button {
              height: 32px;
              background-color: $BASE_YELLOW_COLOR;
              border-radius: 5px;
              color: $BASE_WHITE_COLOR;
              border: none;
              display: flex;
              align-items: center;

              svg {
                width: 15px;
                height: 15px;
                margin-left: 5px;
              }
            }
          }
        }

        .footer {
          display: flex;
          flex-direction: column;
          align-items: center;

          .divider {
            width: 59px;
            height: 1px;
            background-color: $DIVIDER_COLOR;
            margin-bottom: 20px;
          }
          .forgot_password {
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.4px;
            color: $BASE_WHITE_COLOR;
          }

          .copy_right {
            font-weight: normal;
            font-size: 11px;
            line-height: 14px;
            color: $COPY_RIGHT_COLOR;
            margin-top: 43px;
          }
        }
      }
    }
  }
}
