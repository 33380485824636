.map_marker {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: rgba(255, 200, 54, 0.27);

  .oval {
    width: 21px;
    height: 21px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
    border: solid 2px $BASE_WHITE_COLOR;
    border-radius: 50%;
    background-color: $MAP_MAKER_OVAL_COLOR;
  }
  .title {
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    color: $MAP_TOOLTIP_COLOR;
    text-align: center;
  }
  .address {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $BASE_WHITE_COLOR;
    text-align: center;
  }
}
