.single_detail_layout {
  width: 445px;
  border-radius: 5px;
  box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.06);
  background-color: $BASE_WHITE_COLOR;
  margin-top: auto;

  .title {
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $MAIN_TEXT_COLOR;
    margin-bottom: 4px;
  }
  .description {
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: normal;
    color: $FORM_SUBTITLE_COLOR;
    margin-bottom: 25px;
  }

  .ant-form-item {
    padding: 0 28px;
  }

  .ant-radio-wrapper,
  .ant-checkbox-wrapper {
    display: block;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: $MAIN_TEXT_COLOR;
    margin-left: 0px;

    .q_label {
      color: $BASE_YELLOW_COLOR;
      margin-left: 9px;
    }
    .ant-radio-inner,
    .ant-checkbox-inner {
      background-color: $OPTION_BG_COLOR;
    }

    .ant-radio-checked .ant-radio-inner {
      border: none;
    }
    .ant-radio-checked {
      .ant-radio-inner::after {
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;
        background-color: $OPTION_CHECKED_BG_COLOR;
      }
    }
    .ant-checkbox-inner {
      border: none;
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner::after {
        width: 16px;
        height: 16px;
        background-color: $OPTION_CHECKED_BG_COLOR;
        transform: rotate(0deg) translate(-50%, -50%);
        border: none;
        left: 50%;
        top: 50%;
      }
    }

    .ant-checkbox-checked::after {
      border: none;
    }
  }

  textarea {
    height: 77px;
    border-radius: 8px;
    background-color: $MENU_SELECT_BTN_BG_COLOR;
    border: none;
  }

  .product_detail {
    width: 100%;
    padding: 39px 50px 28px 50px;
    background-color: $BASE_WHITE_COLOR;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      max-height: 200px;
    }

    .food_img {
      margin-top: 33px;
    }

    .product_title {
      font-size: 26px;
      line-height: 30px;
      margin-top: 26px;
      margin-bottom: 21px;
      text-align: center;
    }

    .back_btn_wrapper {
      width: 100%;
      text-align: left;

      .back_btn {
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px 7px;
        border-radius: 50%;
        background-color: $SECONDARY_BG_COLOR;
        cursor: pointer;

        svg {
          color: $MAIN_TEXT_COLOR;
        }
      }
    }

    .prefer_menu {
      display: flex;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 115px;
        height: 76px;
        cursor: pointer;

        img {
          margin-bottom: 5px;
        }
      }
      .active {
        border-radius: 11.5px;
        background-color: $MENU_SELECT_BTN_BG_COLOR;
        -webkit-transition: background-color 500ms linear;
        -ms-transition: background-color 500ms linear;
        transition: background-color 500ms linear;
      }
    }
  }

  .recipe_wrapper {
    display: flex;
    justify-content: space-between;
    .ant-checkbox-wrapper {
      display: flex;
      align-items: center;
    }
    .number_picker_wrapper {
      max-width: 80px;
    }
  }

  .group_name {
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $MAIN_TEXT_COLOR;
    margin-bottom: 10px;
  }

  .multi_options {
    .option_recipes {
      border-left: 1px solid $BASE_DARK_GRAY_COLOR;
    }
    .option_multi_select {
      display: flex;
      justify-content: flex-end;

      .ant-form-item {
        width: 100%;
      }

      .ant-form-item-children {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .ant-select {
          .ant-select-selection__rendered {
            margin-right: 0px;
          }
        }
      }
    }
  }

  .single_product_form {
    background-color: $BASE_WHITE_COLOR;
    padding: 50px 26px;

    .btn-wrapper {
      padding-right: 22px;

      button {
        width: 100%;
        height: 113px;
        display: flex;
        justify-content: space-between;
        padding: 20px 20px 22px 31px;
        background-color: $BASE_YELLOW_COLOR;
        border-radius: 10px;
        align-items: center;
        outline: none;
        margin-right: 0px;
        white-space: pre-wrap;
        border: none;
        cursor: pointer;

        div {
          text-align: left;
          margin-right: 10px;
          p:nth-child(1) {
            font-size: 21px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $BASE_WHITE_COLOR;
          }
          p:nth-child(2) {
            font-size: 13px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.54;
            letter-spacing: normal;
            color: $BASE_BROWN_COLOR;
          }
        }

        svg {
          color: $BASE_WHITE_COLOR;
        }
      }
    }
  }
}
