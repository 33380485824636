.product_search_layout {
  position: sticky;
  bottom: 0;
  background-color: $BASE_WHITE_COLOR;
  border-top: 1px solid $DIVIDER_BG_COLOR;
  margin-top: auto;

  .main_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    .ant-input-affix-wrapper {
      width: 210px;

      svg {
        width: 17px;
        height: 21px;
        color: #cfcfcf;
      }
    }
    .hide {
      height: 0;
      overflow: hidden;
      background: $BASE_WHITE_COLOR;
    }
    .show {
      height: 0;
      overflow-y: auto;
      -moz-animation: slide 0.3s ease 0.2s forwards;
      -webkit-animation: slide 0.3s ease 0.2s forwards;
      -o-animation: slide 0.3s ease 0.2s forwards;
      -ms-animation: slide 0.3s ease 0.2s forwards;
      animation: slide 0.3s ease 0.2s forwards;
    }
    .note_show {
      height: 0;
      overflow-y: auto;
      -moz-animation: noteSlide 0.3s ease 0.2s forwards;
      -webkit-animation: noteSlide 0.3s ease 0.2s forwards;
      -o-animation: noteSlide 0.3s ease 0.2s forwards;
      -ms-animation: noteSlide 0.3s ease 0.2s forwards;
      animation: noteSlide 0.3s ease 0.2s forwards;
    }
    .search_result_wrapper {
      position: absolute;
      width: 270px;
      left: 20px;
      bottom: 80px;
      background-color: $BASE_WHITE_COLOR;
      box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.15);

      .item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        cursor: pointer;

        .food_img {
          display: flex;
          width: 60px;
          height: 50px;
          min-width: 60px;
          min-height: 50px;
          border-radius: 8px;
          box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.03);
          align-items: center;
          justify-content: center;
          padding: 8px;
          margin-right: 17px;

          img {
            width: 100%;
          }
        }

        .food_content {
          .name {
            color: $BASE_BLACK_COLOR;
            font-size: 13px;
          }
          .amount {
            font-size: 16px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.25;
            letter-spacing: normal;
            color: $BASE_DARK_BLACK_COLOR;
          }
        }
      }
    }
    .add_note_wrapper {
      position: absolute;
      width: 352px;
      right: 55px;
      bottom: 80px;
      background-color: $BASE_WHITE_COLOR;
      box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.15);
      overflow-y: unset;
      overflow: hidden;
      i {
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
        font-size: 20px;
        color: $BASE_BLACK_COLOR;
      }
      .title {
        margin-bottom: 25px;
      }
      .footer {
        display: flex;
        align-items: center;
        margin-top: 15px;
        justify-content: flex-end;

        p {
          margin-right: 30px;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: $BASE_BLACK_COLOR;
          cursor: pointer;
        }
      }
    }
    .note_refresh_wrapper {
      display: flex;
      align-items: center;
      .warning,
      .note,
      .refresh {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: $SECONDARY_BG_COLOR;
        cursor: pointer;

        svg {
          width: 15px;
        }
      }
      .warning {
        margin-right: 16px;
        .active {
          color: $BASE_YELLOW_COLOR;
        }
      }
      .note {
        margin-right: 16px;
        .active {
          color: $BASE_YELLOW_COLOR;
        }
      }
    }
  }
}

@-moz-keyframes slide /* Firefox */ {
  from {
    height: 0;
  }
  to {
    height: 360px;
    padding: 30px 20px;
  }
}

@-webkit-keyframes slide /* Safari and Chrome */ {
  from {
    height: 0;
  }
  to {
    height: 360px;
    padding: 30px 20px;
  }
}

@-ms-keyframes slide /* IE10 */ {
  from {
    height: 0;
  }
  to {
    height: 360px;
    padding: 30px 20px;
  }
}

@keyframes slide {
  from {
    height: 0;
  }
  to {
    height: 360px;
    padding: 30px 20px;
  }
}

@-moz-keyframes noteSlide /* Firefox */ {
  from {
    height: 0;
  }
  to {
    height: 270px;
    padding: 18px;
  }
}

@-webkit-keyframes noteSlide /* Safari and Chrome */ {
  from {
    height: 0;
  }
  to {
    height: 270px;
    padding: 18px;
  }
}

@-ms-keyframes noteSlide /* IE10 */ {
  from {
    height: 0;
  }
  to {
    height: 270px;
    padding: 18px;
  }
}

@keyframes noteSlide {
  from {
    height: 0;
  }
  to {
    height: 270px;
    padding: 18px;
  }
}
